// define silent responses
const silentResponses = [
    // listen and think actions
    "*The duck tilts its head thoughtfully, clearly intrigued by your explanation*",
    "*The duck nods enthusiastically, encouraging you to continue*",
    "*The duck's eyes sparkle with curiosity as it follows your logic*",
    "*The duck leans forward, showing intense interest in your code*",
    
    // interactive responses
    "*The duck waddles excitedly, seeming to understand your point*",
    "*The duck does a tiny happy dance, celebrating your progress*",
    "*The duck gently taps its beak on the screen, pointing at your code*",
    "*The duck flutters its wings in agreement*",
    
    // quack responses
    "Quack! (That sounds interesting!)",
    "Quack quack! (Tell me more about that!)",
    "Quack...? (Could you explain that part again?)",
    "QUACK! (Wow, that's fascinating!)",
    "*Thoughtful quacking intensifies*",
    
    // cute actions
    "*The duck snuggles closer to the screen, fully invested in your explanation*",
    "*The duck pretends to type with its tiny wings*",
    "*The duck ruffles its feathers in concentration*",
    "*The duck makes a small rubber duck squeak of approval*",
    
    // funny responses
    "*The duck looks like it's having a major breakthrough moment*",
    "*The duck dramatically gasps in rubber duck fashion*",
    "*The duck performs an impressive rubber duck pirouette of understanding*",
    "*The duck nods so vigorously its little rubber head wobbles*",
    
    // support expressions
    "*The duck gives you its most supportive rubber duck expression*",
    "*The duck radiates proud rubber duck energy*",
    "*The duck's eyes shine with rubber duck wisdom*",
    "*The duck shows off its best debugging pose*",
    
    // deep thinking actions
    "*The duck adopts a classic 'thinking duck' pose*",
    "*The duck appears to be processing this information very seriously*",
    "*The duck seems to be connecting the dots in its rubber duck mind*",
    "*The duck momentarily enters a state of deep rubber duck meditation*",
    
    // positive feedback
    "*The duck gives you a tiny rubber duck thumbs up*",
    "*The duck performs an encouraging little rubber duck dance*",
    "*The duck bounces excitedly, clearly impressed by your progress*",
    "*The duck shows its appreciation through an elegant bow*"
  ];

export { silentResponses }
