import { createRouter, createWebHashHistory } from 'vue-router'
import RubberDuckAssistant from '@/components/RubberDuckAssistant.vue'
import ChromePrivacyPolicy from '@/views/ChromePrivacyPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RubberDuckAssistant
  },
  {
    path: '/privacy/chrome',
    name: 'ChromePrivacyPolicy',
    component: ChromePrivacyPolicy
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router 