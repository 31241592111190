<template>
  <div class="code-editor h-full flex flex-col">
    <div class="language-selector mb-4">
      <select 
        v-model="selectedLanguage" 
        @change="updateLanguage" 
        class="glass-select"
      >
        <option v-for="lang in languages" :key="lang.id" :value="lang.id">
          {{ lang.name }}
        </option>
      </select>
    </div>
    <div ref="editorContainer" class="editor-container glass-inset flex-grow"></div>
    <dialog ref="inputDialog" class="input-dialog glass-morphism" @click="handleDialogClick">
      <div class="dialog-content" @click.stop>
        <div class="dialog-header">
          <h3>Required Inputs</h3>
          <p class="dialog-description">
            This code requires user input. Please provide the following values:
          </p>
        </div>

        <div class="dialog-body">
          <div class="input-list">
            <!-- normal input -->
            <div v-if="hasRegularInputs" class="input-group">
              <div class="input-group-title">Regular Inputs</div>
              <div v-for="(input, index) in regularInputs" 
                   :key="'regular-' + index" 
                   class="input-item">
                <label :for="`input-regular-${index}`" class="input-label">{{ input.prompt }}:</label>
                <input 
                  :id="`input-regular-${index}`"
                  v-model="input.value"
                  :type="input.type"
                  class="input-field"
                  :placeholder="input.placeholder"
                >
              </div>
            </div>

            <!-- loop input -->
            <div v-if="hasLoopInputs" class="input-group">
              <div class="input-group-title">
                Loop Inputs 
                <span class="input-count">
                  ({{ loopInputs.length }} inputs required)
                </span>
              </div>
              <div class="loop-inputs-container">
                <div v-for="(input, index) in loopInputs" 
                     :key="'loop-' + index" 
                     class="input-item">
                  <label :for="`input-loop-${index}`" class="input-label">{{ input.prompt }}:</label>
                  <input 
                    :id="`input-loop-${index}`"
                    v-model="input.value"
                    :type="input.type"
                    class="input-field"
                    :placeholder="input.placeholder"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog-footer">
          <button @click="cancelInputs" class="cancel-button">
            Cancel
          </button>
          <button @click="confirmInputs" class="confirm-button" :disabled="!allInputsFilled">
            Run Code
          </button>
        </div>
      </div>
    </dialog>
    <div class="control-panel glass-inset">
      <button @click="runCode" class="run-button">
        <i class="fas fa-play"></i>
        <span>Run Code</span>
      </button>
      
      <div class="output-container">
        <div class="output-header">
          <i class="fas fa-terminal"></i>
          <span>Output</span>
        </div>
        <div class="output-content">{{ output }}</div>
      </div>
    </div>
    <!-- add quote hint component -->
    <Transition name="fade">
      <div v-if="showQuoteHint" class="quote-hint">
        <div class="quote-content">
          <span class="quote-lines">Selected lines {{ selectedCode.startLine }}-{{ selectedCode.endLine }}</span>
          <button @click="handleQuote" class="quote-button">
            <i class="fas fa-quote-right"></i>
            <span>Quote Selection</span>
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue'
import * as monaco from 'monaco-editor';
import loader from '@monaco-editor/loader';

export default {
  name: 'CodeEditor',
  emits: ['codeChanged', 'selectionChanged'],
  setup(props, { emit }) {
    const languages = ref([
      { id: 'python', name: 'Python', version: '3.10.0' },
      { id: 'javascript', name: 'JavaScript', version: '16.3.0' },
      { id: 'java', name: 'Java', version: '15.0.2' },
      { id: 'c', name: 'C', version: '10.2.0' },
      { id: 'cpp', name: 'C++', version: '10.2.0' },
      { id: 'csharp', name: 'C#', version: '6.12.0' },
      { id: 'go', name: 'Go', version: '1.16.2' },
      { id: 'ruby', name: 'Ruby', version: '3.0.1' },
    ])
    const selectedLanguage = ref('python')
    const output = ref('')
    const editorContainer = ref(null)
    let editor = null

    const languageTemplates = {
      python: `# Welcome to Python!
# This program demonstrates string manipulation and basic calculations
name = input("Enter your name: ")
age = int(input("Enter your age: "))

# Calculate some fun facts
days_lived = age * 365
hours_lived = days_lived * 24

print(f"Hello {name}! Here are some fun facts about you:")
print(f"1. You've lived for approximately {days_lived:,} days")
print(f"2. That's about {hours_lived:,} hours!")
print(f"3. Your name has {len(name)} letters")
print(f"4. Your name backwards is: {name[::-1]}")`,

      javascript: `// Welcome to JavaScript!
// This program demonstrates string and array operations
const name = prompt("Enter your name: ");
const favoriteColor = prompt("Enter your favorite color: ");

// Create a personalized message
console.log("Hello, " + name + "! Let's analyze your choices:");

// Analyze the name
const nameLength = name.length;
const nameUppercase = name.toUpperCase();
const nameLowercase = name.toLowerCase();

console.log("1. Your name has " + nameLength + " characters");
console.log("2. In uppercase: " + nameUppercase);
console.log("3. In lowercase: " + nameLowercase);
console.log("4. Your favorite color (" + favoriteColor + ") has " + favoriteColor.length + " letters");`,

      java: `// Welcome to Java!
// This program demonstrates string manipulation and math operations
public class Main {
    public static void main(String[] args) {
        String text = "Hello, Java Programmer!";
        
        // String operations
        System.out.println("Original text: " + text);
        System.out.println("Length: " + text.length());
        System.out.println("Uppercase: " + text.toUpperCase());
        System.out.println("Lowercase: " + text.toLowerCase());
        
        // Math operations
        int a = 7, b = 3;
        System.out.println("\\nMath Operations (a=7, b=3):");
        System.out.println("Addition: " + (a + b));
        System.out.println("Subtraction: " + (a - b));
        System.out.println("Multiplication: " + (a * b));
        System.out.println("Division: " + (a / (double)b));
    }
}`,

      c: `// Welcome to C!
// This program demonstrates array operations and pattern printing
#include <stdio.h>

int main() {
    // Create a simple pattern
    int size = 5;
    printf("Creating a triangle pattern of size %d:\\n\\n", size);
    
    for (int i = 1; i <= size; i++) {
        // Print spaces
        for (int j = size - i; j > 0; j--) {
            printf(" ");
        }
        // Print stars
        for (int k = 1; k <= 2 * i - 1; k++) {
            printf("*");
        }
        printf("\\n");
    }
    
    return 0;
}`,

      cpp: `// Welcome to C++!
// This program demonstrates string manipulation and vector operations
#include <iostream>
#include <vector>
#include <string>
using namespace std;

int main() {
    vector<int> numbers = {1, 2, 3, 4, 5};
    string message = "Hello, C++ Programmer!";
    
    // Vector operations
    cout << "Vector operations:" << endl;
    cout << "Original numbers: ";
    for (int num : numbers) {
        cout << num << " ";
    }
    
    // Calculate sum and average
    int sum = 0;
    for (int num : numbers) {
        sum += num;
    }
    double average = static_cast<double>(sum) / numbers.size();
    
    cout << "\\nSum: " << sum << endl;
    cout << "Average: " << average << endl;
    
    // String operations
    cout << "\\nString operations:" << endl;
    cout << "Message: " << message << endl;
    cout << "Length: " << message.length() << endl;
    
    return 0;
}`,

      csharp: `// Welcome to C#!
// This program demonstrates string manipulation and list operations
using System;
using System.Linq;
using System.Collections.Generic;

class Program {
    static void Main(string[] args) {
        // Create a list of numbers
        List<int> numbers = new List<int> { 1, 2, 3, 4, 5 };
        
        Console.WriteLine("List operations:");
        Console.WriteLine("Numbers: " + string.Join(", ", numbers));
        Console.WriteLine("Sum: " + numbers.Sum());
        Console.WriteLine("Average: " + numbers.Average().ToString("F2"));
        Console.WriteLine("Maximum: " + numbers.Max());
        Console.WriteLine("Minimum: " + numbers.Min());
        
        // String operations
        string message = "Hello, C# Programmer!";
        Console.WriteLine("\\nString operations:");
        Console.WriteLine("Message: " + message);
        Console.WriteLine("Length: " + message.Length);
        Console.WriteLine("Uppercase: " + message.ToUpper());
        Console.WriteLine("Contains 'Program': " + message.Contains("Program"));
    }
}`,

      go: `// Welcome to Go!
// This program demonstrates string manipulation and slice operations
package main

import (
    "fmt"
    "strings"
)

func main() {
    // String operations
    message := "Hello, Go Programmer!"
    words := strings.Split(message, " ")
    
    fmt.Println("String Analysis:")
    fmt.Printf("Original message: %s\\n", message)
    fmt.Printf("Number of words: %d\\n", len(words))
    fmt.Printf("Uppercase: %s\\n", strings.ToUpper(message))
    fmt.Printf("Lowercase: %s\\n", strings.ToLower(message))
    
    // Slice operations
    numbers := []int{1, 2, 3, 4, 5}
    sum := 0
    for _, num := range numbers {
        sum += num
    }
    
    fmt.Printf("\\nNumber Analysis:\\n")
    fmt.Printf("Numbers: %v\\n", numbers)
    fmt.Printf("Sum: %d\\n", sum)
    fmt.Printf("Average: %.2f\\n", float64(sum)/float64(len(numbers)))
}`,

      ruby: `# Welcome to Ruby!
# This program demonstrates string manipulation and array operations
message = "Hello, Ruby Programmer!"
numbers = [1, 2, 3, 4, 5]

# String operations
puts "String Analysis:"
puts "Original message: #{message}"
puts "Length: #{message.length}"
puts "Uppercase: #{message.upcase}"
puts "Lowercase: #{message.downcase}"
puts "Words: #{message.split.join(', ')}"

# Array operations
puts "\\nNumber Analysis:"
puts "Numbers: #{numbers.join(', ')}"
puts "Sum: #{numbers.sum}"
puts "Average: #{numbers.sum.to_f / numbers.length}"
puts "Maximum: #{numbers.max}"
puts "Minimum: #{numbers.min}"`
    }

    const inputDialog = ref(null);
    const inputValues = ref([]);
    const pendingExecution = ref(false);

    const allInputsFilled = computed(() => {
      return inputValues.value.every(input => input.value.trim() !== '');
    });

    const updateLanguage = () => {
      if (editor) {
        const model = editor.getModel();
        monaco.editor.setModelLanguage(model, selectedLanguage.value);
        editor.setValue(languageTemplates[selectedLanguage.value]);
      }
      emit('codeChanged', { language: selectedLanguage.value, code: editor.getValue() })
    }

    const runCode = async () => {
      const code = editor.getValue();
      const detectedInputs = detectInputs(code);

      if (detectedInputs.length > 0) {
        if (detectedInputs.length > 10) {
          output.value = 'Error: Too many input statements (maximum 10 allowed)';
          return;
        }

        inputValues.value = detectedInputs;
        pendingExecution.value = true;
        await nextTick();
        inputDialog.value.showModal();
        return;
      }

      executeCode(code);
    };

    const confirmInputs = () => {
      if (!allInputsFilled.value) return;
      
      const codeToRun = prepareCodeWithInputs();
      inputDialog.value.close();
      pendingExecution.value = false;
      executeCode(codeToRun);
      inputValues.value = [];
    };

    const cancelInputs = () => {
      inputDialog.value.close();
      pendingExecution.value = false;
      inputValues.value = [];
    };

    const handleDialogClick = (e) => {
      if (e.target === inputDialog.value) {
        cancelInputs();
      }
    };

    const prepareCodeWithInputs = () => {
      const code = editor.getValue();
      let inputSimulation = '';
      
      switch (selectedLanguage.value) {
        case 'python': {
          const allInputValues = inputValues.value.map(i => i.value);
          inputSimulation = `_all_input_values = ${JSON.stringify(allInputValues)}
_input_index = 0

def input(prompt=""):
    global _input_index
    if _input_index >= len(_all_input_values):
        raise IndexError("Not enough input values provided")
    print(prompt + _all_input_values[_input_index])  # 打印提示和输入
    value = _all_input_values[_input_index]
    _input_index += 1
    return value
`;
          break;
        }
        case 'javascript': {
          inputSimulation = `const input_values = ${JSON.stringify(inputValues.value.map(i => i.value))};
let input_index = 0;
const prompt = (msg) => {
  const value = input_values[input_index++];
  console.log(msg + value);
  return value;
};`;
          break;
        }
      }

      return inputSimulation + '\n' + code;
    };

    const executeCode = async (code) => {
      output.value = 'Running code...';
      try {
        const response = await fetch('https://emkc.org/api/v2/piston/execute', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            language: selectedLanguage.value,
            version: '*',
            files: [{ content: code }],
            stdin: '',
            args: [],
            compile_timeout: 10000,
            run_timeout: 3000,
            compile_memory_limit: -1,
            run_memory_limit: -1
          }),
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const result = await response.json();
        output.value = result.run?.output || result.message || 'Unknown error occurred.';
      } catch (error) {
        output.value = `Error: ${error.message}`;
      }
    };

    const detectInputs = (code) => {
      console.log('Detecting inputs for code:', code);

      const inputDetectionPatterns = {
        python: {
          pattern: /input\(['"](.*?)['"].*?\)/g,
          type: 'text'
        },
        javascript: {
          pattern: /prompt\(['"](.*?)['"].*?\)/g,
          type: 'text'
        }
      };

      const pattern = inputDetectionPatterns[selectedLanguage.value];
      if (!pattern) return [];

      const inputs = [];
      let loopCount = 0;

      // detect loop
      if (selectedLanguage.value === 'python') {
        const forLoopMatch = code.match(/for\s+\w+\s+in\s+range\s*\((\d+)\)/);
        if (forLoopMatch) {
          loopCount = parseInt(forLoopMatch[1]);
          console.log('Detected loop with count:', loopCount);
        }
      }

      // collect all input statements
      const matches = [...code.matchAll(pattern.pattern)];
      console.log('Found input matches:', matches);

      // analyze each input statement
      matches.forEach((match) => {
        const prompt = match[1];
        const position = match.index;
        const codeBeforeMatch = code.slice(0, position);
        
        // improve loop detection logic
        const isInLoop = loopCount > 0 && 
                        codeBeforeMatch.includes('for') && 
                        codeBeforeMatch.lastIndexOf('for') < position &&
                        code.slice(codeBeforeMatch.lastIndexOf('for'), position).includes(':');

        if (isInLoop) {
          // create input field for each iteration in loop
          for (let i = 0; i < loopCount; i++) {
            inputs.push({
              prompt: `Round ${i + 1}: ${prompt}`,
              value: '',
              type: 'text',
              group: 'Loop Inputs',
              round: i + 1,
              placeholder: `Enter value for round ${i + 1}`
            });
          }
        } else {
          // normal input
          inputs.push({
            prompt,
            value: '',
            type: 'text',
            group: 'Regular Inputs',
            placeholder: `Enter value for: ${prompt}`
          });
        }
      });

      console.log('Generated input fields:', inputs);
      return inputs;
    };

    const regularInputs = computed(() => 
      inputValues.value.filter(input => input.group === 'Regular Inputs')
    );

    const loopInputs = computed(() => 
      inputValues.value.filter(input => input.group === 'Loop Inputs')
    );

    const hasRegularInputs = computed(() => regularInputs.value.length > 0);
    const hasLoopInputs = computed(() => loopInputs.value.length > 0);

    const selectedCode = ref({
      startLine: 0,
      endLine: 0,
      text: '',
      language: ''
    });
    const showQuoteHint = ref(false);

    const handleQuote = () => {
      const lineRange = `lines ${selectedCode.value.startLine}-${selectedCode.value.endLine}`;
      const quoteText = `Let me refer to ${lineRange}:\n\`\`\`${selectedCode.value.language}\n${selectedCode.value.text}\n\`\`\`\n`;
      
      // trigger quote event
      emit('quote', quoteText);
      showQuoteHint.value = false;
    };

    const handleSelection = () => {
      const selection = editor.getSelection();
      if (selection && !selection.isEmpty()) {
        const startLine = selection.startLineNumber;
        const endLine = selection.endLineNumber;
        const selectedText = editor.getModel().getValueInRange(selection);
        
        // if the number of selected lines exceeds a certain number, add ellipsis hint
        const maxPreviewLines = 20;
        let displayText = selectedText;
        const lineCount = selectedText.split('\n').length;
        
        if (lineCount > maxPreviewLines) {
          const lines = selectedText.split('\n');
          const firstLines = lines.slice(0, maxPreviewLines / 2);
          const lastLines = lines.slice(-maxPreviewLines / 2);
          displayText = [...firstLines, '...', ...lastLines].join('\n');
        }

        selectedCode.value = {
          startLine,
          endLine,
          text: selectedText,
          displayText,
          language: selectedLanguage.value
        };
        
        showQuoteHint.value = true;

        // auto hide hint after 5 seconds
        setTimeout(() => {
          showQuoteHint.value = false;
        }, 5000);

        // trigger selection change event
        emit('selectionChanged', {
          startLine,
          endLine,
          selectedText,
          language: selectedLanguage.value
        });
      }
    };

    onMounted(() => {
      loader.init().then((monaco) => {
        editor = monaco.editor.create(editorContainer.value, {
          value: languageTemplates[selectedLanguage.value],
          language: selectedLanguage.value,
          theme: 'vs-dark',
          automaticLayout: true,
          minimap: { enabled: false },
          fontSize: 14,
          lineHeight: 1.5,
          padding: { top: 10, bottom: 10 },
          scrollbar: {
            vertical: 'visible',
            horizontal: 'visible',
            useShadows: false,
            verticalScrollbarSize: 10,
            horizontalScrollbarSize: 10
          }
        });

        window.addEventListener('resize', () => {
          if (editor) {
            editor.layout();
          }
        });

        emit('codeChanged', { 
          language: selectedLanguage.value, 
          code: editor.getValue() 
        });

        editor.onDidChangeModelContent(() => {
          emit('codeChanged', { 
            language: selectedLanguage.value, 
            code: editor.getValue() 
          });
        });

        editor.onDidChangeCursorSelection(() => {
          handleSelection();
        });
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', () => {
        if (editor) {
          editor.layout();
        }
      });
      if (editor) {
        editor.dispose();
      }
    });

    watch(selectedLanguage, updateLanguage)

    return {
      languages,
      selectedLanguage,
      output,
      editorContainer,
      updateLanguage,
      runCode,
      inputValues,
      allInputsFilled,
      inputDialog,
      confirmInputs,
      cancelInputs,
      handleDialogClick,
      regularInputs,
      loopInputs,
      hasRegularInputs,
      hasLoopInputs,
      selectedCode,
      showQuoteHint,
      handleQuote,
    }
  }
}
</script>