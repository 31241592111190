<template>
  <div class="rubber-duck-container">
    <div class="layout-container">
      <!-- left code editor -->
      <div class="code-editor-section glass-inset">
        <CodeEditor 
          @codeChanged="handleCodeChange"
          @quote="handleQuote" 
        />
      </div>

      <!-- right chat and voice interaction area -->
      <div class="chat-section">
        <!-- top toolbar -->
        <div class="top-toolbar glass-inset">
          <div class="toolbar-content">
            <!-- left rubber duck -->
            <div class="duck-icon" :class="{ 'animate-wiggle': isAnimating }" @click="playDuckAnimation">
              <img :src="duckImage" alt="Rubber Duck" class="duck-image">
              <audio ref="quackAudio" preload="auto">
                <source :src="quackSound" type="audio/mpeg">
              </audio>
            </div>

            <!-- middle mode selector -->
            <select 
              v-model="selectedMode" 
              class="mode-select"
              :disabled="isProcessing || isContinuousMode"
              :title="isContinuousMode ? 'Please stop continuous chat first' : (isProcessing ? 'Please wait for current operation to complete' : 'Select mode')"
            >
              <option v-for="mode in modes" :key="mode" :value="mode">
                {{ mode }}
              </option>
            </select>

            <!-- right clear button -->
            <button @click="clearChat" class="clear-button" title="Clear chat history">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>

        <!-- chat container -->
        <div class="chat-container glass-inset">
          <div class="messages-wrapper" ref="messagesWrapper">
            <div v-for="message in chatHistory" :key="message.id" 
                 :class="[
                   message.sender, 
                   'message',
                   {
                     'generating-audio': isMessageGeneratingAudio(message.id),
                     'playing-audio': isMessagePlaying(message.id)
                   }
                 ]">
              <!-- only add play button for AI messages -->
              <button v-if="message.sender === 'assistant' && selectedMode !== 'Silent Duck'"
                      @click="playMessage(message)"
                      class="message-play-button"
                      :class="{ 
                        'playing': isMessagePlaying(message.id),
                        'loading': isMessageGeneratingAudio(message.id)
                      }"
                      :disabled="isMessageGeneratingAudio(message.id)"
                      :title="getPlayButtonTitle(message.id)">
                <i class="fas" :class="getPlayButtonIcon(message.id)"></i>
              </button>
              
              <!-- original message content rendering -->
              <template v-for="(block, index) in parseMessage(message.text)" :key="index">
                <!-- code block -->
                <div v-if="block.type === 'code'" class="code-block glass-inset">
                  <div class="code-header">
                    <span class="language-label">{{ block.language }}</span>
                    <button 
                      @click="copyCode(block.content)" 
                      class="copy-button" 
                      :class="{ 'copied': copiedStates[block.content] }"
                      title="Copy code"
                    >
                      <i class="fas" :class="copiedStates[block.content] ? 'fa-check' : 'fa-copy'"></i>
                      <span class="copy-text">{{ copiedStates[block.content] ? 'Copied!' : 'Copy' }}</span>
                    </button>
                  </div>
                  <pre><code :class="block.language" v-html="highlightCode(block.content, block.language)"></code></pre>
                </div>
                <!-- normal text -->
                <div v-else class="text-content">{{ block.content }}</div>
              </template>
            </div>
            <div v-if="selectedMode === 'Silent' && silentResponse" 
                 class="assistant message italic">
              {{ silentResponse }}
            </div>
            
            <!-- loading indicator -->
            <div v-if="isLoading || isGeneratingAudio" class="loading-indicator">
              <div class="loading-spinner"></div>
              <span>{{ loadingMessage }}</span>
            </div>
          </div>
        </div>

        <!-- voice interaction quick control -->
        <div class="voice-interaction-bar glass-inset">
          <div class="voice-controls">
            <div class="voice-record-group">
              <!-- record button - only show when not in continuous mode -->
              <button 
                v-if="!isContinuousMode"
                @click="toggleRecording"
                class="voice-record-button"
                :class="{ 
                  'recording': isRecording,
                  'disabled': !isMicrophoneEnabled 
                }"
                :disabled="isContinuousMode || !isMicrophoneEnabled"
                :title="!isMicrophoneEnabled ? 'Please enable microphone first' : (isRecording ? 'Finish recording' : 'Start voice input')"
              >
                <div class="button-content">
                  <i class="fas" :class="isRecording ? 'fa-check' : 'fa-microphone'"></i>
                  <span class="button-text">{{ isRecording ? 'Finish Recording' : 'Voice Input' }}</span>
                </div>
                <div v-if="isRecording" class="recording-status">
                  <div class="pulse-dot"></div>
                  <span>{{ recordingDuration }}s</span>
                </div>
              </button>

              <!-- continuous chat button - only show when not recording -->
              <button 
                v-if="!isRecording"
                @click="toggleContinuousMode"
                class="continuous-mode-button"
                :class="{ 
                  'active': isContinuousMode,
                  'expanded': isContinuousMode || !isRecording,
                  'disabled': !isMicrophoneEnabled 
                }"
                :disabled="!isMicrophoneEnabled"
                :title="!isMicrophoneEnabled ? 'Please enable microphone first' : (isContinuousMode ? 'Stop continuous chat' : 'Start continuous chat')"
              >
                <div class="button-content">
                  <i class="fas" :class="isContinuousMode ? 'fa-comment-slash' : 'fa-comments'"></i>
                  <span class="button-text">{{ isContinuousMode ? 'Stop Chat' : 'Continuous Chat' }}</span>
                </div>
              </button>

              <!-- modify status hint to button style -->
              <div v-if="isContinuousMode" 
                   class="status-button"
                   :class="[
                     continuousModeState,
                     { 'disabled': isPlaying || isProcessing || isGeneratingAudio }
                   ]">
                <div class="status-content">
                  <i class="fas" :class="getStatusIcon"></i>
                  <span class="status-text">{{ getStatusMessage }}</span>
                  <div class="status-indicator"></div>
                </div>
              </div>
            </div>

            <!-- waveform display -->
            <div v-if="isRecording" class="waveform-display">
              <div class="audio-visualizer">
                <div v-for="n in 32" :key="n" class="wave-bar"></div>
              </div>
            </div>

            <!-- audio play control button -->
            <button 
              v-if="isPlaying"
              @click="stopCurrentAudio"
              class="audio-control-button"
              title="Stop playback"
            >
              <i class="fas fa-stop"></i>
            </button>

            <!-- microphone control button -->
            <button 
              @click="toggleMicrophoneAccess"
              class="microphone-control-button"
              :class="{ 'disabled': !isMicrophoneEnabled }"
              :title="isMicrophoneEnabled ? 'Disable microphone' : 'Enable microphone'"
            >
              <i class="fas" :class="isMicrophoneEnabled ? 'fa-microphone' : 'fa-microphone-slash'"></i>
            </button>

            <!-- settings button -->
            <button 
              @click="openVoiceSettings"
              class="voice-settings-button"
              title="Voice Settings"
            >
              <i class="fas fa-sliders-h"></i>
            </button>
          </div>
        </div>

        <!-- voice settings dialog -->
        <dialog ref="voiceSettingsDialog" class="settings-dialog glass-morphism" @click="handleDialogClick">
          <div class="dialog-content" @click.stop>
            <div class="dialog-header">
              <div class="header-title">
                <i class="fas fa-sliders-h"></i>
                <h3>Voice Settings</h3>
              </div>
              <button @click="closeVoiceSettings" class="close-button" title="Close settings">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div class="dialog-body">
              <!-- TTS switch -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-volume-up"></i>
                    <span>Text-to-Speech</span>
                  </div>
                  <label class="switch">
                    <input type="checkbox" v-model="ttsEnabled">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="setting-description">
                  Automatically read AI responses aloud
                </div>
              </div>

              <!-- voice selection -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-microphone-alt"></i>
                    <span>Voice Selection</span>
                  </div>
                </div>
                <div class="voice-options">
                  <button 
                    v-for="voice in voices" 
                    :key="voice.id"
                    @click="selectedVoice = voice.id"
                    :class="['voice-option', { active: selectedVoice === voice.id }]"
                  >
                    <div class="voice-icon">
                      <i :class="voice.icon"></i>
                    </div>
                    <div class="voice-info">
                      <div class="voice-name">{{ voice.name }}</div>
                      <div class="voice-description">{{ voice.description }}</div>
                    </div>
                    <div class="voice-check">
                      <i class="fas fa-check"></i>
                    </div>
                  </button>
                </div>
              </div>

              <!-- volume control -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas" :class="volumeIcon"></i>
                    <span>Volume</span>
                  </div>
                  <span class="volume-value">{{ volume }}%</span>
                </div>
                <div class="volume-slider-container">
                  <input 
                    type="range" 
                    v-model="volume" 
                    min="0" 
                    max="100" 
                    class="volume-slider"
                    :style="{ '--volume-percentage': volume + '%' }"
                  >
                </div>
              </div>

              <!-- add timeout setting group -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-clock"></i>
                    <span>Inactivity Timeout</span>
                  </div>
                </div>
                <div class="setting-description">
                  Automatically stop continuous chat mode after period of inactivity
                </div>
                <div class="timeout-slider-container">
                  <div class="timeout-value">
                    <span>{{ formatTimeout(inactivityTimeout) }}</span>
                  </div>
                  <input 
                    type="range" 
                    v-model="inactivityTimeout" 
                    min="10" 
                    max="300" 
                    step="10"
                    class="timeout-slider"
                    :style="{ '--timeout-percentage': (inactivityTimeout - 10) / (300 - 10) * 100 + '%' }"
                  >
                  <div class="timeout-labels">
                    <span>10s</span>
                    <span>5m</span>
                  </div>
                </div>
              </div>

              <!-- add privacy policy link -->
              <div class="setting-group privacy-group">
                <router-link to="/privacy/chrome" class="privacy-link" @click="closeVoiceSettings">
                  <i class="fas fa-shield-alt"></i>
                  <span>Privacy Policy</span>
                  <i class="fas fa-chevron-right privacy-arrow"></i>
                </router-link>
              </div>
            </div>

            <div class="dialog-footer">
              <button @click="closeVoiceSettings" class="confirm-button">
                <div class="button-content">
                  <i class="fas fa-check"></i>
                  <span>Apply Settings</span>
                </div>
                <div class="button-background"></div>
              </button>
            </div>
          </div>
        </dialog>

        <!-- input area -->
        <div class="input-container glass-inset">
          <input 
            v-model="userInput" 
            @keydown.enter.prevent="handleEnterPress"
            @compositionstart="isComposing = true"
            @compositionend="isComposing = false"
            placeholder="Explain your code..." 
            class="chat-input"
            :disabled="isSending || isProcessing"
          >
          <button 
            @click="sendMessage" 
            class="send-button"
            :disabled="isSending || isProcessing || !userInput.trim()"
          >
            <i class="fas" :class="isSending ? 'fa-spinner fa-spin' : 'fa-paper-plane'"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- add clear confirm dialog -->
    <dialog ref="clearConfirmDialog" class="settings-dialog glass-morphism" @click="handleClearDialogClick">
      <div class="dialog-content" @click.stop>
        <div class="dialog-header">
          <div class="header-title">
            <i class="fas fa-trash-alt"></i>
            <h3>Clear Chat History</h3>
          </div>
          <button @click="closeClearConfirm" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="dialog-body">
          <p class="confirm-message">
            Are you sure you want to clear the current chat history? 
            <span class="confirm-detail">This will only clear the chat history for the current mode ({{ selectedMode }}).</span>
          </p>
        </div>

        <div class="dialog-footer">
          <div class="button-group">
            <button @click="closeClearConfirm" class="cancel-button">
              Cancel
            </button>
            <button @click="confirmClear" class="danger-button">
              <div class="button-content">
                <i class="fas fa-trash-alt"></i>
                <span>Clear History</span>
              </div>
              <div class="button-background"></div>
            </button>
          </div>
        </div>
      </div>
    </dialog>

    <!-- add status hint component -->
    <Transition name="fade">
      <div v-if="statusMessage" class="status-toast" :class="statusType">
        <div class="status-content">
          <div class="status-icon">
            <i class="fas" :class="statusIcon"></i>
          </div>
          <span class="status-text">{{ statusMessage }}</span>
        </div>
        <div class="progress-bar"></div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, watch, nextTick, onUnmounted, computed, onMounted } from 'vue'
import duckImage from '@/assets/rubber-duck.png'
import CodeEditor from './CodeEditor.vue'
import hljs from 'highlight.js'
import { getApiKey, isValidEnvironment } from '../config/api'
// import voice file
import quackSound from '@/assets/sounds/quack.mp3'
import { silentResponses } from '../config/silentresponse'
import { modePrompts } from '../config/modeprompts'


export default {
  name: 'RubberDuckAssistant',
  components: {
    CodeEditor
  },
  props: {
    currentCode: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // first define localCode
    const localCode = ref({
      code: '',
      language: 'javascript'
    });

    // then other state definitions
    const modes = ['Silent Duck', 'Beginner Friend', 'Expert'];
    const selectedMode = ref('Silent Duck');
    const isAnimating = ref(false);
    const userInput = ref('');
    const chatHistories = ref({
      'Silent Duck': [],
      'Beginner Friend': [],
      'Expert': []
    });

    // now it's safe to use watch
    watch(() => props.currentCode, (newCode) => {
      if (newCode) {
        localCode.value = {
          code: newCode.code || '',
          language: newCode.language || 'javascript'
        };
      }
    }, { deep: true, immediate: true });

    // update code method
    const updateCode = (newCode) => {
      const updatedCode = {
        code: newCode,
        language: localCode.value.language
      };
      emit('update:currentCode', updatedCode);
      localCode.value = updatedCode;
    };

    const handleTranscription = (text) => {
      if (text && text.trim()) {
        // if input box already has content, add new text after it
        userInput.value = userInput.value
          ? `${userInput.value}\n\n${text.trim()}`  // use two line breaks to make text clearer
          : text.trim();
        
        // if not in continuous mode, send message immediately
        if (!isContinuousMode.value) {
          sendMessage();
        }
      }
    };

    const isComposing = ref(false);
    const isSending = ref(false);

    const handleEnterPress = () => {
      if (isComposing.value || isSending.value || !userInput.value.trim()) {
        return;
      }
      sendMessage();
    };

    const isPlaying = ref(false);
    let currentAudio = null;

    // stop current audio playback
    const stopCurrentAudio = async () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        URL.revokeObjectURL(currentAudio.src);
        currentAudio = null;
        isPlaying.value = false;
        playingMessageId.value = null;
        
        // if still in continuous mode, resume recording
        if (isContinuousMode.value) {
          await resumeRecording();
        }
      }
    };

    // add scroll to bottom method
    const scrollToBottom = () => {
      if (messagesWrapper.value) {
        const scrollOptions = {
          top: messagesWrapper.value.scrollHeight,
          behavior: 'smooth'
        };
        messagesWrapper.value.scrollTo(scrollOptions);
      }
    };

    // modify send message method
    const sendMessage = async () => {
      if (isSending.value || !userInput.value?.trim()) return;

      stopCurrentAudio();
      isSending.value = true;
      isLoading.value = true;

      try {
        const messageText = userInput.value;
        userInput.value = '';

        const currentCodeState = {
          code: localCode.value.code || '',
          language: localCode.value.language || 'javascript'
        };

        // update current mode's history
        const currentHistory = [...chatHistories.value[selectedMode.value]];
        currentHistory.push({ 
          id: Date.now(), 
          sender: 'user', 
          text: messageText,
          code: currentCodeState
        });
        chatHistories.value[selectedMode.value] = currentHistory;

        // scroll to bottom to show user message
        nextTick(scrollToBottom);

        animateDuck();
        
        if (selectedMode.value === 'Silent Duck') {
          // Silent Duck mode processing
          const responseCount = Math.floor(Math.random() * 2) + 2;
          const responses = [];
          const usedIndices = new Set();
          
          while (responses.length < responseCount) {
            const index = Math.floor(Math.random() * silentResponses.length);
            if (!usedIndices.has(index)) {
              usedIndices.add(index);
              responses.push(silentResponses[index]);
            }
          }
          
          const response = responses.join('\n');
          
          currentHistory.push({ 
            id: Date.now(), 
            sender: 'assistant', 
            text: response,
            code: currentCodeState
          });
          chatHistories.value[selectedMode.value] = currentHistory;
        } else {
          const response = await callOpenAI(
            selectedMode.value, 
            messageText, 
            currentCodeState,
            currentHistory
          );

          currentHistory.push({ 
            id: Date.now(), 
            sender: 'assistant', 
            text: response,
            code: currentCodeState
          });
          chatHistories.value[selectedMode.value] = currentHistory;

          // scroll to bottom to show assistant reply
          nextTick(scrollToBottom);

          if (ttsEnabled.value) {
            isGeneratingAudio.value = true;
            // scroll to bottom to show audio generation hint
            nextTick(scrollToBottom);

            try {
              const audioResponse = await fetch('https://api.openai.com/v1/audio/speech', {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${getApiKey().split('').reverse().join('')}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  model: 'tts-1',
                  voice: selectedVoice.value,
                  input: response
                })
              });

              const audioBlob = await audioResponse.blob();
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              
              // set audio events
              audio.volume = volume.value / 100;
              currentAudio = audio;
              // set current playing message ID
              playingMessageId.value = currentHistory[currentHistory.length - 1].id;
              
              audio.onplay = () => {
                isPlaying.value = true;
              };
              
              audio.onended = () => {
                isPlaying.value = false;
                playingMessageId.value = null;
                URL.revokeObjectURL(audioUrl);
                currentAudio = null;
              };
              
              audio.onpause = () => {
                isPlaying.value = false;
                playingMessageId.value = null;
              };
              
              await audio.play();
            } catch (err) {
              console.error('Error playing TTS:', err);
            } finally {
              isGeneratingAudio.value = false;
              // last scroll to ensure all content is visible
              nextTick(scrollToBottom);
            }
          }
        }

        // save updated history
        saveChatsToLocalStorage();

        // after message sent, if in continuous mode, reinitialize audio listening
        if (isContinuousMode.value) {
          await reinitializeAudioContext();
        }
      } catch (error) {
        console.error('Error sending message:', error);
        showStatus('Failed to send message', 'error');
      } finally {
        isSending.value = false;
        isLoading.value = false;
        nextTick(scrollToBottom);
      }
    };

    const callOpenAI = async (mode, message, codeState, currentHistory) => {
      if (!isValidEnvironment()) {
        throw new Error('Invalid environment for API calls');
      }

      const apiKey = getApiKey();
      if (!apiKey) {
        throw new Error('Failed to get API key');
      }
      
      // add code formatting function with line numbers
      const formatCodeWithLineNumbers = (code, language) => {
        if (!code?.trim()) return '';
        const lines = code.split('\n');
        const numberedLines = lines.map((line, index) => `${index + 1}| ${line}`).join('\n');
        return `\n\nCurrent code (${language}):\n\`\`\`${language}\n${numberedLines}\n\`\`\``;
      };

      // check and format code context
      const codeContext = codeState.code?.trim() 
        ? formatCodeWithLineNumbers(codeState.code, codeState.language)
        : '';

      // modify message history formatting
      const messageHistory = currentHistory
        .filter(msg => msg.code?.code?.trim() || !msg.code)
        .map(msg => {
          const msgCodeContext = msg.code?.code?.trim()
            ? formatCodeWithLineNumbers(msg.code.code, msg.code.language)
            : '';
          return {
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text + msgCodeContext
          };
        });

      // add line number explanation in system message
      const systemMessage = `${modePrompts[mode]}\n\nWhen users refer to specific line numbers in their messages, you can find the line content in the numbered code format (e.g., "1| console.log('Hello')"). Please reference these line numbers in your responses when discussing specific code lines.`;

      console.log('Sending request with messages:', {
        mode,
        message,
        codeContext,
        messageHistory
      });

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey.split('').reverse().join('')}`
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            { 
              role: 'system', 
              content: systemMessage
            },
            ...messageHistory,
            { 
              role: 'user', 
              content: message + codeContext
            }
          ]
        })
      });

      if (!response.ok) {
        console.error('OpenAI API error:', await response.json());
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    };

    const clearAllChats = () => {
      Object.keys(chatHistories.value).forEach(mode => {
        chatHistories.value[mode] = [];
      });
      saveChatsToLocalStorage();
    };

    watch(selectedMode, (newMode, oldMode) => {
      if (newMode !== oldMode) {
        // stop current audio playback
        stopCurrentAudio();
        
        // if processing, revert to original mode
        if (isProcessing.value) {
          selectedMode.value = oldMode;
          showStatus('Please wait for current operation to complete', 'warning');
          return;
        }
      }
    });

    const handleProcessingStart = () => {
      // handle speech to text start
    };

    const handleProcessingError = () => {
      console.error('Speech processing error');
    };

    const animateDuck = () => {
      isAnimating.value = true
      setTimeout(() => { isAnimating.value = false }, 2000)
    }

    const handleCodeChange = (codeData) => {
      console.log('Code changed:', codeData);
      if (codeData && (codeData.code !== localCode.value.code || codeData.language !== localCode.value.language)) {
        localCode.value = {
          code: codeData.code || '',
          language: codeData.language || 'javascript'
        };
        emit('update:currentCode', localCode.value);
      }
    };

    // add new state
    const showVoiceSettings = ref(false);
    const chatContainer = ref(null);
    const messagesWrapper = ref(null);

    // toggle voice settings panel
    const toggleVoiceSettings = () => {
      showVoiceSettings.value = !showVoiceSettings.value;
    };

    // add chatHistory computed property
    const chatHistory = computed(() => chatHistories.value[selectedMode.value] || []);

    // add new state variables
    const isRecording = ref(false);
    const recordingDuration = ref(0);
    let recordingTimer = null; // no ref needed as it's not reactive

    // add voiceInteraction ref
    const voiceInteraction = ref(null);

    // modify toggleRecording method
    const toggleRecording = async () => {
      if (!isRecording.value) {
        try {
          // clear previous audio data before starting new recording
          audioChunks.value = [];
          
          await initAudioContext();
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          
          // create audio source and analyzer connection
          if (audioContext.value && analyser.value) {
            const source = audioContext.value.createMediaStreamSource(stream);
            source.connect(analyser.value);
          }
          
          mediaRecorder.value = new MediaRecorder(stream);
          
          mediaRecorder.value.ondataavailable = (event) => {
            audioChunks.value.push(event.data);
          };

          mediaRecorder.value.onstop = async () => {
            // only process audio data if not cancelled
            if (audioChunks.value.length > 0 && mediaRecorder.value && !mediaRecorder.value.cancelled) {
              isProcessing.value = true;
              const audioBlob = new Blob(audioChunks.value, { type: 'audio/wav' });
              await sendAudioToOpenAI(audioBlob);
              audioChunks.value = [];
              isProcessing.value = false;
            }
            // ensure cleanup after stopping
            if (mediaRecorder.value?.stream) {
              mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
            }
          };

          mediaRecorder.value.start();
          isRecording.value = true;
          startRecordingTimer();
        } catch (err) {
          console.error('Error starting recording:', err);
          showStatus('Failed to start recording', 'error');
          isRecording.value = false;
          stopRecordingTimer();
        }
      } else {
        if (mediaRecorder.value) {
          // mark this as normal stop, not cancelled
          mediaRecorder.value.cancelled = false;
          mediaRecorder.value.stop();
          // ensure immediate cleanup of audio stream
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        isRecording.value = false;
        stopRecordingTimer();
      }
    };

    // add recording timer related methods
    const startRecordingTimer = () => {
      recordingDuration.value = 0;
      recordingTimer = setInterval(() => {
        recordingDuration.value += 1;
      }, 1000);
    };

    const stopRecordingTimer = () => {
      if (recordingTimer) {
        clearInterval(recordingTimer);
        recordingTimer = null;
      }
      // ensure cleanup of audio stream
      if (mediaRecorder.value?.stream) {
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
    };

    // cleanup timer on component unmount
    onUnmounted(() => {
      stopRecordingTimer();
      stopCurrentAudio();
      // ensure cleanup of audio context and stream
      if (audioContext.value) {
        audioContext.value.close();
      }
      if (mediaRecorder.value?.stream) {
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
    });

    // add message parsing method
    const parseMessage = (text) => {
      const blocks = [];
      let currentIndex = 0;

      // regex for code blocks
      const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
      let match;

      while ((match = codeBlockRegex.exec(text)) !== null) {
        // add text before code block
        if (match.index > currentIndex) {
          blocks.push({
            type: 'text',
            content: text.slice(currentIndex, match.index)
          });
        }

        // add code block
        blocks.push({
          type: 'code',
          language: match[1] || 'text',
          content: match[2].trim()
        });

        currentIndex = match.index + match[0].length;
      }

      // add remaining text
      if (currentIndex < text.length) {
        blocks.push({
          type: 'text',
          content: text.slice(currentIndex)
        });
      }

      return blocks;
    };

    // add copy code method
    const copiedStates = ref({});

    // modify copy code method
    const copyCode = async (code) => {
      try {
        await navigator.clipboard.writeText(code);
        copiedStates.value[code] = true;
        setTimeout(() => {
          copiedStates.value[code] = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy code:', err);
      }
    };

    // add code highlighting method
    const highlightCode = (code, language) => {
      try {
        if (language) {
          return hljs.highlight(code, { language }).value;
        }
        return hljs.highlightAuto(code).value;
      } catch (e) {
        console.warn('Failed to highlight code:', e);
        return code;
      }
    };

    // add status hint method
    const showStatus = (message, type = 'info', duration = 3000) => {
      statusMessage.value = message;
      statusType.value = type;
      
      // auto hide
      setTimeout(() => {
        statusMessage.value = '';
        statusType.value = '';
      }, duration);
    };

    // add audio context and analyser
    const audioContext = ref(null);
    const analyser = ref(null);
    const mediaRecorder = ref(null);
    const audioChunks = ref([]);
    const dataArray = ref(null);

    // modify initAudioContext method
    const initAudioContext = async () => {
      if (!audioContext.value) {
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;
        dataArray.value = new Uint8Array(analyser.value.frequencyBinCount);

        // update waveform bar height
        const updateWaveform = () => {
          if (!isRecording.value) return;
          
          analyser.value.getByteTimeDomainData(dataArray.value);
          
          // use data to update waveform bar height
          const bars = document.querySelectorAll('.wave-bar');
          const step = Math.floor(dataArray.value.length / bars.length);
          
          bars.forEach((bar, index) => {
            const dataIndex = index * step;
            const value = dataArray.value[dataIndex] / 128.0; // 将值归一化到 0-2 范围
            const height = Math.max(0.3, Math.min(1, value)) * 100; // 限制高度在 30%-100% 之间
            bar.style.height = `${height}%`;
          });

          requestAnimationFrame(updateWaveform);
        };

        // start waveform animation
        updateWaveform();
      }
    };

    // add audio related methods
    const sendAudioToOpenAI = async (audioBlob) => {
      try {
        const formData = new FormData();
        formData.append('file', audioBlob);
        formData.append('model', 'whisper-1');
        
        const apiKey = getApiKey();
        if (!apiKey) {
          throw new Error('Failed to get API key');
        }

        const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${apiKey.split('').reverse().join('')}`
          },
          body: formData
        });
        
        const data = await response.json();
        handleTranscription(data.text);
      } catch (err) {
        console.error('Error in speech to text:', err);
        handleProcessingError(err);
      }
    };

    // add voice related states
    const ttsEnabled = ref(true); // default to enable playback
    const selectedVoice = ref('nova');
    const volume = ref(80);
    const voiceSettingsDialog = ref(null);

    // voice settings related methods
    const openVoiceSettings = () => {
      voiceSettingsDialog.value?.showModal();
    };

    const closeVoiceSettings = () => {
      voiceSettingsDialog.value?.close();
    };

    const voices = [
      {
        id: 'alloy',
        name: 'Alloy',
        description: 'Balanced and professional',
        icon: 'fas fa-user-tie'
      },
      {
        id: 'echo',
        name: 'Echo',
        description: 'Warm and engaging',
        icon: 'fas fa-smile'
      },
      {
        id: 'fable',
        name: 'Fable',
        description: 'Expressive and dynamic',
        icon: 'fas fa-theater-masks'
      },
      {
        id: 'onyx',
        name: 'Onyx',
        description: 'Deep and authoritative',
        icon: 'fas fa-user-shield'
      },
      {
        id: 'nova',
        name: 'Nova',
        description: 'Friendly and approachable',
        icon: 'fas fa-user-friends'
      },
      {
        id: 'shimmer',
        name: 'Shimmer',
        description: 'Clear and articulate',
        icon: 'fas fa-comment-dots'
      }
    ];

    const quackAudio = ref(null);

    const playDuckAnimation = () => {
      if (isAnimating.value) return;

      isAnimating.value = true;
      
      // play sound effect, adjust volume
      if (quackAudio.value) {
        quackAudio.value.currentTime = 0;
        quackAudio.value.volume = 0.3; // adjust volume to 30%
        quackAudio.value.play().catch(err => {
          console.error('Error playing quack sound:', err);
        });
      }

      // reset state after animation ends
      setTimeout(() => {
        isAnimating.value = false;
      }, 1000);
    };

    // load history on component mount
    onMounted(() => {
      loadChatsFromLocalStorage();
    });

    // add silent response state
    const silentResponse = ref('');

    // add local storage related methods
    const STORAGE_KEY = 'rubber-duck-chat-histories';

    const saveChatsToLocalStorage = () => {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(chatHistories.value));
      } catch (e) {
        console.error('Failed to save chat histories:', e);
      }
    };

    const loadChatsFromLocalStorage = () => {
      try {
        const saved = localStorage.getItem(STORAGE_KEY);
        if (saved) {
          chatHistories.value = JSON.parse(saved);
        }
      } catch (e) {
        console.error('Failed to load chat histories:', e);
      }
    };

    const clearConfirmDialog = ref(null);

    // modify clear related methods
    const showClearConfirm = () => {
      clearConfirmDialog.value?.showModal();
    };

    const closeClearConfirm = () => {
      clearConfirmDialog.value?.close();
    };

    const handleClearDialogClick = (e) => {
      if (e.target === clearConfirmDialog.value) {
        closeClearConfirm();
      }
    };

    const confirmClear = () => {
      // stop current audio playback
      stopCurrentAudio();
      
      // if processing, do not allow clear
      if (isProcessing.value) {
        showStatus('Please wait for current operation to complete', 'warning');
        return;
      }

      chatHistories.value[selectedMode.value] = [];
      saveChatsToLocalStorage();
      closeClearConfirm();
      
      // show success hint
      showStatus('Chat history cleared successfully', 'success');
    };

    // modify clear button click handling
    const clearChat = () => {
      showClearConfirm();
    };

    // add new states
    const isLoading = ref(false);
    const isGeneratingAudio = ref(false);
    const loadingMessage = computed(() => {
      if (isGeneratingAudio.value) return 'Generating audio response...';
      if (isLoading.value) return 'Processing your message...';
      return '';
    });

    // add volume icon computed property
    const volumeIcon = computed(() => {
      if (volume.value === 0) return 'fa-volume-mute';
      if (volume.value < 30) return 'fa-volume-off';
      if (volume.value < 70) return 'fa-volume-down';
      return 'fa-volume-up';
    });

    const statusMessage = ref('');
    const statusType = ref('');
    
    // status icon computed property
    const statusIcon = computed(() => {
      switch (statusType.value) {
        case 'success':
          return 'fa-check-circle';
        case 'error':
          return 'fa-exclamation-circle';
        case 'info':
          return 'fa-info-circle';
        default:
          return 'fa-info-circle';
      }
    });

    // add processing state
    const isProcessing = computed(() => {
      return isSending.value || isGeneratingAudio.value || isLoading.value;
    });

    const playingMessageId = ref(null);

    const isMessagePlaying = (messageId) => {
      return playingMessageId.value === messageId;
    };

    const playMessage = async (message) => {
      // if playing this message, stop playback
      if (isMessagePlaying(message.id)) {
        await stopCurrentAudio();
        return;
      }

      try {
        // stop current audio playback
        await stopCurrentAudio();
        
        // if recording, pause recording
        if (mediaRecorder.value?.state === 'recording') {
          pauseRecording();
        }
        
        generatingAudioMessageId.value = message.id;
        
        const audioResponse = await fetch('https://api.openai.com/v1/audio/speech', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${getApiKey().split('').reverse().join('')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'tts-1',
            voice: selectedVoice.value,
            input: message.text
          })
        });

        const audioBlob = await audioResponse.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        
        audio.volume = volume.value / 100;
        currentAudio = audio;
        playingMessageId.value = message.id;
        
        audio.onplay = () => {
          isPlaying.value = true;
          // pause volume monitoring
          pauseVolumeMonitoring();
        };
        
        audio.onended = async () => {
          isPlaying.value = false;
          playingMessageId.value = null;
          URL.revokeObjectURL(audioUrl);
          currentAudio = null;
          
          // if still in continuous mode, resume recording
          if (isContinuousMode.value) {
            await resumeRecording();
          }
        };
        
        audio.onpause = () => {
          isPlaying.value = false;
          playingMessageId.value = null;
        };
        
        await audio.play();
      } catch (err) {
        console.error('Error playing message:', err);
        showStatus('Failed to play message', 'error');
      } finally {
        generatingAudioMessageId.value = null;
      }
    };

    const cancelRecording = () => {
      if (mediaRecorder.value) {
        // mark this as cancel operation
        mediaRecorder.value.cancelled = true;
        
        // ensure stop recording
        try {
          mediaRecorder.value.stop();
        } catch (e) {
          console.error('Error stopping recorder:', e);
        }
        // ensure cleanup of audio stream
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        mediaRecorder.value = null;
      }
      // immediately clear audio data
      audioChunks.value = [];
      stopRecordingTimer();
      isRecording.value = false;
      
      showStatus('Recording cancelled', 'info');
    };

    // add new states in setup
    const isContinuousMode = ref(false);
    const continuousModeState = ref('waiting');
    const silenceTimer = ref(null);
    const silenceThreshold = 0.01; // lower threshold
    const silenceDelay = 1500; // reduce wait time to 1.5 seconds

    // continuous mode state and message
    const continuousModeMessage = computed(() => {
      switch (continuousModeState.value) {
        case 'listening':
          return 'Listening... Speak freely';
        case 'processing':
          return 'Processing your message...';
        case 'waiting':
          return 'Waiting for response...';
        default:
          return '';
      }
    });

    const toggleContinuousMode = async () => {
      if (!isContinuousMode.value) {
        try {
          await startContinuousMode();
        } catch (err) {
          console.error('Failed to start continuous mode:', err);
          showStatus('Failed to start continuous chat', 'error');
        }
      } else {
        stopContinuousMode();
      }
    };

    const startContinuousMode = async () => {
      if (!isMicrophoneEnabled.value) {
        showStatus('Please enable microphone first', 'warning');
        return;
      }
      
      try {
        // stop any existing audio context
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }

        // initialize new audio context and analyser
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;
        
        // request microphone permission and get audio stream
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        // create audio source and connect analyser
        const source = audioContext.value.createMediaStreamSource(stream);
        source.connect(analyser.value);
        
        // initialize recorder
        if (mediaRecorder.value) {
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        mediaRecorder.value = new MediaRecorder(stream);
        audioChunks.value = [];
        
        // set up recording data processing
        mediaRecorder.value.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.value.push(event.data);
          }
        };
        
        // restart volume monitoring
        await startVolumeMonitoring();
        
        // update state
        isContinuousMode.value = true;
        continuousModeState.value = 'listening';
        showStatus('Continuous chat mode activated', 'success');
      } catch (err) {
        console.error('Error initializing audio:', err);
        stopContinuousMode();
        throw new Error('Failed to initialize audio system');
      }
    };

    const stopContinuousMode = () => {
      if (mediaRecorder.value) {
        mediaRecorder.value.stop();
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
      if (audioContext.value) {
        audioContext.value.close().catch(console.error);
        audioContext.value = null;
      }
      if (analyser.value) {
        analyser.value = null;
      }
      clearTimeout(silenceTimer.value);
      
      isContinuousMode.value = false;
      continuousModeState.value = 'waiting';
      showStatus('Continuous chat mode deactivated', 'info');
    };

    const startVolumeMonitoring = () => {
      if (!analyser.value) return;
      
      const dataArray = new Float32Array(analyser.value.frequencyBinCount);
      let isSpeaking = false;
      let isProcessing = false;
      let volumeHistory = [];
      const historyLength = 10;
      let isMonitoring = true;
      let noSoundTimer = null;
      
      const checkVolume = () => {
        if (!analyser.value || !isMonitoring || isPlaying.value || isProcessing.value || isGeneratingAudio.value) {
          requestAnimationFrame(checkVolume);
          return;
        }
        
        analyser.value.getFloatTimeDomainData(dataArray);
        const volumeLevel = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
        
        volumeHistory.push(volumeLevel);
        if (volumeHistory.length > historyLength) {
          volumeHistory.shift();
        }
        
        const avgVolume = volumeHistory.reduce((a, b) => a + b, 0) / volumeHistory.length;
        const volumeDropped = volumeHistory.length === historyLength && volumeLevel < avgVolume * 0.5;
        
        if (volumeLevel > silenceThreshold) {
          // stop current playback when large sound is detected
          if (isPlaying.value) {
            stopCurrentAudio();
          }
          
          if (!isSpeaking && !isProcessing) {
            startNewRecording();
            isSpeaking = true;
            volumeHistory = [volumeLevel];
          }
          // reset no sound timer
          clearTimeout(noSoundTimer);
          noSoundTimer = setTimeout(() => {
            if (isContinuousMode.value) {
              stopContinuousMode();
              showStatus(`Continuous chat ended due to ${formatTimeout(inactivityTimeout.value)} inactivity`, 'info');
            }
          }, inactivityTimeout.value * 1000); // use set timeout value
          
          clearTimeout(silenceTimer.value);
          silenceTimer.value = null;
        } else if (isSpeaking && volumeDropped && !silenceTimer.value) {
          silenceTimer.value = setTimeout(async () => {
            if (isSpeaking && !isProcessing) {
              isProcessing = true;
              isSpeaking = false;
              await finishRecording();
              isProcessing = false;
              volumeHistory = [];
              silenceTimer.value = null;
            }
          }, silenceDelay);
        }
        
        if (isMonitoring && !isPlaying.value && !isProcessing.value && !isGeneratingAudio.value) {
          requestAnimationFrame(checkVolume);
        }
      };
      
      checkVolume();
      
      return () => {
        isMonitoring = false;
        clearTimeout(noSoundTimer);
      };
    };

    const startNewRecording = () => {
      console.log('startNewRecording called', {
        mediaRecorderExists: !!mediaRecorder.value,
        mediaRecorderState: mediaRecorder.value?.state
      });
      
      audioChunks.value = [];
      if (mediaRecorder.value && mediaRecorder.value.state === 'inactive') {
        console.log('Starting media recorder');
        mediaRecorder.value.start();
        continuousModeState.value = 'listening';
      } else {
        console.log('Media recorder not ready or already recording');
      }
    };

    // modify finishRecording method
    const finishRecording = async () => {
      console.log('finishRecording called', {
        mediaRecorderExists: !!mediaRecorder.value,
        mediaRecorderState: mediaRecorder.value?.state,
        chunksLength: audioChunks.value.length
      });
      
      if (mediaRecorder.value && mediaRecorder.value.state === 'recording') {
        console.log('Stopping recording and processing audio');
        continuousModeState.value = 'processing';
        
        try {
          // create a Promise to wait for audio data
          const audioData = await new Promise((resolve, reject) => {
            const chunks = [];
            const timeout = setTimeout(() => {
              reject(new Error('Timeout waiting for audio data'));
            }, 5000);

            // stop recording first
            mediaRecorder.value.stop();
            
            mediaRecorder.value.ondataavailable = (event) => {
              if (event.data.size > 0) {
                chunks.push(event.data);
              }
            };
            
            mediaRecorder.value.onstop = () => {
              clearTimeout(timeout);
              resolve(chunks);
            };
          });
          
          if (audioData.length > 0) {
            const audioBlob = new Blob(audioData, { type: 'audio/wav' });
            console.log('Sending audio blob:', audioBlob.size);
            
            // send audio data for transcription
            await sendAudioToOpenAI(audioBlob);
            
            // send message immediately after transcription
            if (userInput.value.trim()) {
              await sendMessage();
            }
          } else {
            console.log('No audio data to process');
          }
        } catch (err) {
          console.error('Error in finishRecording:', err);
          showStatus('Failed to process audio', 'error');
        } finally {
          console.log('Finishing recording process');
          continuousModeState.value = 'listening';
          audioChunks.value = []; // clear audio data
        }
      } else {
        console.log('Media recorder not in recording state');
      }
    };

    // ensure cleanup of resources on component unmount
    onUnmounted(() => {
      stopContinuousMode();
    });

    // add new state in setup
    const isMicrophoneEnabled = ref(true);

    // add microphone control method
    const toggleMicrophoneAccess = async () => {
      if (isMicrophoneEnabled.value) {
        // if current is enabled, disable microphone
        isMicrophoneEnabled.value = false;
        
        // stop all recording related activities
        if (isRecording.value) {
          await toggleRecording();
        }
        if (isContinuousMode.value) {
          stopContinuousMode();
        }
        
        // ensure release of all media streams
        if (mediaRecorder.value?.stream) {
          mediaRecorder.value.stream.getTracks().forEach(track => {
            track.stop();
            track.enabled = false;
          });
        }
        
        // close audio context
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }
        
        // reset all related states
        isRecording.value = false;
        isContinuousMode.value = false;
        continuousModeState.value = 'waiting';
        
        showStatus('Microphone disabled', 'info');
      } else {
        // if current is disabled, request microphone permission
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach(track => track.stop()); // immediately stop stream, just test permission
          isMicrophoneEnabled.value = true;
          showStatus('Microphone enabled', 'success');
        } catch (err) {
          console.error('Failed to get microphone permission:', err);
          showStatus('Failed to enable microphone', 'error');
        }
      }
    };

    // add status icon computed property
    const getStatusIcon = computed(() => {
      if (isPlaying.value) {
        return 'fa-volume-up';
      }
      if (isGeneratingAudio.value) {
        return 'fa-spinner fa-spin';
      }
      if (isProcessing.value) {
        return 'fa-spinner fa-spin';
      }
      switch (continuousModeState.value) {
        case 'listening':
          return 'fa-microphone';
        case 'processing':
          return 'fa-spinner fa-spin';
        case 'waiting':
          return 'fa-clock';
        default:
          return 'fa-microphone-slash';
      }
    });

    // add new ref to track message generating audio
    const generatingAudioMessageId = ref(null);

    // add method to check if message is generating audio
    const isMessageGeneratingAudio = (messageId) => {
      return generatingAudioMessageId.value === messageId;
    };

    // add method to get play button title
    const getPlayButtonTitle = (messageId) => {
      if (isMessageGeneratingAudio(messageId)) return 'Generating audio...';
      if (isMessagePlaying(messageId)) return 'Stop';
      return 'Play message';
    };

    // add method to get play button icon
    const getPlayButtonIcon = (messageId) => {
      if (isMessageGeneratingAudio(messageId)) return 'fa-spinner fa-spin';
      if (isMessagePlaying(messageId)) return 'fa-square';
      return 'fa-play';
    };

    // add computed property to get status message
    const getStatusMessage = computed(() => {
      if (isPlaying.value) {
        return 'Playback in progress...';
      }
      if (isGeneratingAudio.value) {
        return 'Generating audio response...';
      }
      if (isProcessing.value) {
        return 'Processing your message...';
      }
      return continuousModeMessage.value;
    });

    // add pause recording method
    const pauseRecording = () => {
      if (mediaRecorder.value?.state === 'recording') {
        try {
          mediaRecorder.value.pause();
          continuousModeState.value = 'paused';
          showStatus('Recording paused', 'info');
        } catch (err) {
          console.error('Error pausing recording:', err);
        }
      }
    };

    // add resume recording method
    const resumeRecording = async () => {
      if (!isContinuousMode.value || !isMicrophoneEnabled.value) return;
      
      if (isPlaying.value || isProcessing.value || isGeneratingAudio.value) {
        return;
      }
      
      try {
        // not simply resume, but fully reinitialize
        await reinitializeAudioContext();
        showStatus('Recording resumed', 'success');
      } catch (err) {
        console.error('Failed to resume recording:', err);
        showStatus('Failed to resume recording', 'error');
        stopContinuousMode();
      }
    };

    // add pause volume monitoring method
    const pauseVolumeMonitoring = () => {
      if (audioContext.value) {
        try {
          audioContext.value.suspend();
        } catch (err) {
          console.error('Error suspending audio context:', err);
        }
      }
    };

    // add resume volume monitoring method
    const resumeVolumeMonitoring = () => {
      if (audioContext.value?.state === 'suspended') {
        try {
          audioContext.value.resume();
        } catch (err) {
          console.error('Error resuming audio context:', err);
        }
      }
    };

    // add new ref
    const inactivityTimeout = ref(30); // default 30 seconds

    // add method to format timeout time
    const formatTimeout = (seconds) => {
      if (seconds < 60) {
        return `${seconds} seconds`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 0) {
        return `${minutes} minutes`;
      }
      return `${minutes}m ${remainingSeconds}s`;
    };

    // add watch to save timeout setting to local storage
    watch(inactivityTimeout, (newValue) => {
      try {
        localStorage.setItem('inactivityTimeout', newValue.toString());
      } catch (e) {
        console.error('Failed to save inactivity timeout:', e);
      }
    }, { immediate: true });

    // load saved timeout setting on component mount
    onMounted(() => {
      const savedTimeout = localStorage.getItem('inactivityTimeout');
      if (savedTimeout) {
        inactivityTimeout.value = parseInt(savedTimeout, 10);
      }
    });

    // add method to reinitialize audio context
    const reinitializeAudioContext = async () => {
      try {
        // clear existing audio context
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }
        if (analyser.value) {
          analyser.value = null;
        }

        // reinitialize audio context and analyser
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;

        // reinitialize microphone stream
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const source = audioContext.value.createMediaStreamSource(stream);
        source.connect(analyser.value);

        // reinitialize recorder
        if (mediaRecorder.value) {
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        mediaRecorder.value = new MediaRecorder(stream);
        audioChunks.value = [];

        // set up recording data processing
        mediaRecorder.value.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.value.push(event.data);
          }
        };

        // restart volume monitoring
        await startVolumeMonitoring();

        // update state
        continuousModeState.value = 'listening';
      } catch (err) {
        console.error('Error reinitializing audio context:', err);
        showStatus('Failed to reinitialize audio system', 'error');
        stopContinuousMode();
      }
    };

    // add new state
    const handleQuote = (quoteText) => {
      // if input box already has content, add quote in new line
      userInput.value = userInput.value 
        ? userInput.value + '\n\n' + quoteText 
        : quoteText;
      
      // focus input box and scroll to bottom
      nextTick(() => {
        const inputEl = document.querySelector('.chat-input');
        if (inputEl) {
          inputEl.focus();
          inputEl.scrollTop = inputEl.scrollHeight;
        }
      });
    };

    return {
      modes,
      selectedMode,
      duckImage,
      isAnimating,
      userInput,
      chatHistory,
      silentResponse,
      clearChat,
      clearAllChats,
      handleTranscription,
      handleProcessingStart,
      handleProcessingError,
      sendMessage,
      voiceInteraction,
      updateCode,
      handleCodeChange,
      showVoiceSettings,
      toggleVoiceSettings,
      chatContainer,
      messagesWrapper,
      toggleRecording,
      startRecordingTimer,
      stopRecordingTimer,
      isRecording,
      recordingDuration,
      isComposing,
      isSending,
      handleEnterPress,
      parseMessage,
      copiedStates,
      highlightCode,
      copyCode,
      showStatus,
      isPlaying,
      stopCurrentAudio,
      isProcessing,
      mediaRecorder,
      audioChunks,
      audioContext,
      sendAudioToOpenAI,
      ttsEnabled,
      selectedVoice,
      volume,
      voiceSettingsDialog,
      openVoiceSettings,
      closeVoiceSettings,
      voices,
      quackSound,
      quackAudio,
      playDuckAnimation,
      localCode,
      saveChatsToLocalStorage,
      loadChatsFromLocalStorage,
      chatHistories,
      clearConfirmDialog,
      showClearConfirm,
      closeClearConfirm,
      handleClearDialogClick,
      confirmClear,
      isLoading,
      isGeneratingAudio,
      loadingMessage,
      scrollToBottom,
      volumeIcon,
      statusMessage,
      statusType,
      statusIcon,
      playMessage,
      isMessagePlaying,
      playingMessageId,
      cancelRecording,
      isContinuousMode,
      continuousModeState,
      continuousModeMessage,
      toggleContinuousMode,
      toggleMicrophoneAccess,
      isMicrophoneEnabled,
      getStatusIcon,
      isMessageGeneratingAudio,
      getPlayButtonTitle,
      getPlayButtonIcon,
      getStatusMessage,
      pauseRecording,
      resumeRecording,
      pauseVolumeMonitoring,
      resumeVolumeMonitoring,
      inactivityTimeout,
      formatTimeout,
      handleQuote,
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  margin-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.privacy-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #94a3b8;
  font-size: 0.875rem;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.privacy-link:hover {
  color: #60a5fa;
  background: rgba(96, 165, 250, 0.1);
}

.privacy-link i {
  font-size: 0.875rem;
}

.privacy-group {
  margin-top: 1rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

.privacy-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #94a3b8;
  font-size: 0.875rem;
  text-decoration: none;
  padding: 0.75rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  width: 100%;
}

.privacy-link:hover {
  color: #60a5fa;
  background: rgba(96, 165, 250, 0.1);
}

.privacy-link i {
  font-size: 0.875rem;
}

.privacy-arrow {
  margin-left: auto;
  font-size: 0.75rem;
  opacity: 0.5;
}

.dialog-footer {
  margin-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}
</style>