<template>
  <div class="privacy-policy">
    <div class="container glass-morphism">
      <div class="header">
        <img src="@/assets/rubber-duck.png" alt="Rubber Duck" class="logo">
        <h1>Privacy Policy</h1>
        <p class="subtitle">Rubber Ducking for Chrome Extension</p>
      </div>
      
      <div class="content">
        <section>
          <h2><i class="fas fa-info-circle"></i> Introduction</h2>
          <p>Rubber Ducking for Chrome ("we", "our", or "the extension") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our Chrome extension.</p>
        </section>

        <section>
          <h2><i class="fas fa-database"></i> Information Collection</h2>
          <p>We collect and process the following types of information:</p>
          <ul>
            <li><strong>API Keys:</strong> Your API keys for AI services (such as OpenAI) are stored locally in your browser using Chrome's secure storage system.</li>
            <li><strong>Selected Code:</strong> When you highlight code in your editor, this content is temporarily processed to generate AI responses.</li>
            <li><strong>User Queries:</strong> The questions you ask about your code are temporarily processed to generate responses.</li>
            <li><strong>Settings Preferences:</strong> Your chosen settings (such as AI model preferences and voice settings) are stored locally.</li>
          </ul>
        </section>

        <section>
          <h2><i class="fas fa-tasks"></i> Use of Information</h2>
          <p>The information we collect is used exclusively for:</p>
          <ul>
            <li><i class="fas fa-robot"></i> Providing AI-powered code assistance and explanations</li>
            <li><i class="fas fa-key"></i> Authenticating with AI service providers</li>
            <li><i class="fas fa-cog"></i> Maintaining your preferred settings</li>
            <li><i class="fas fa-chart-line"></i> Improving the extension's functionality</li>
          </ul>
        </section>

        <section>
          <h2><i class="fas fa-shield-alt"></i> Data Storage and Security</h2>
          <p>We prioritize the security of your information:</p>
          <ul>
            <li><i class="fas fa-browser"></i> All data is stored locally in your browser</li>
            <li><i class="fas fa-lock"></i> API keys are securely stored using Chrome's storage API</li>
            <li><i class="fas fa-server"></i> No data is permanently stored on our servers</li>
            <li><i class="fas fa-memory"></i> Code snippets and conversations are processed in memory only</li>
          </ul>
        </section>

        <section>
          <h2><i class="fas fa-share-alt"></i> Data Sharing</h2>
          <p>We share data only in the following limited circumstances:</p>
          <ul>
            <li>With AI service providers (e.g., OpenAI) to process your code-related queries</li>
            <li>Your API keys are only used to authenticate with their respective services</li>
            <li>We never sell, rent, or share your information with third parties for marketing purposes</li>
          </ul>
        </section>

        <section>
          <h2><i class="fas fa-user-shield"></i> User Rights and Control</h2>
          <p>You have full control over your data:</p>
          <ul>
            <li><i class="fas fa-key"></i> Access and modify your stored API keys at any time</li>
            <li><i class="fas fa-trash-alt"></i> Clear all stored data through the extension settings</li>
            <li><i class="fas fa-sliders-h"></i> Choose which AI models to use</li>
            <li><i class="fas fa-toggle-on"></i> Control when and how the extension activates</li>
          </ul>
        </section>

        <section>
          <h2><i class="fas fa-plug"></i> Third-Party Services</h2>
          <p>Our extension integrates with the following services:</p>
          <ul>
            <li><i class="fas fa-brain"></i> OpenAI API - for processing code queries (when configured)</li>
            <li><i class="fas fa-database"></i> Chrome Storage API - for securely storing your preferences</li>
          </ul>
          <p>Please review the privacy policies of these services for additional information about how they handle your data.</p>
        </section>

        <section>
          <h2><i class="fas fa-bell"></i> Updates to Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify users of any material changes through the extension or our website. Continued use of the extension after such modifications constitutes acceptance of the updated Privacy Policy.</p>
        </section>

        <section>
          <h2><i class="fas fa-envelope"></i> Contact Information</h2>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <ul>
            <li><i class="fas fa-globe"></i> Website: <a href="https://rubberducking.viablelab.org">https://rubberducking.viablelab.org</a></li>
            <li><i class="fas fa-envelope"></i> Email: viablelab.org@gmail.com</li>
          </ul>
        </section>

        <div class="footer">
          <p class="last-updated">Last Updated: January 2025</p>
          <router-link to="/" class="back-button">
            <i class="fas fa-arrow-left"></i> Back to Home
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import duckImage from '@/assets/rubber-duck.png'

export default {
  name: 'ChromePrivacyPolicy',
  setup() {
    const logo = ref(duckImage)
    return { logo }
  }
}
</script>

<style scoped>
.privacy-policy {
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #f3f4f6;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.logo {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
  color: #60a5fa;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.1rem;
  color: #94a3b8;
}

.content {
  padding: 1rem;
}

section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

h2 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #60a5fa;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

h2 i {
  font-size: 1.25rem;
  opacity: 0.9;
}

p {
  margin-bottom: 1rem;
  line-height: 1.7;
}

ul {
  list-style: none;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

li i {
  color: #60a5fa;
  width: 1.5rem;
  text-align: center;
}

a {
  color: #60a5fa;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #93c5fd;
}

.footer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.last-updated {
  color: #94a3b8;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: rgba(96, 165, 250, 0.1);
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 8px;
  color: #60a5fa;
  font-weight: 500;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(96, 165, 250, 0.2);
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 1rem;
  }

  .container {
    padding: 1.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  section {
    padding: 1rem;
  }
}
</style> 